/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";
/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';
/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "theme/mixins";

@font-face {
  font-family: Isonorm;
  src: url("/assets/fonts/IsonormRegular.otf");
}

* {
  font-family: Isonorm, sans-serif !important;
}


.background {
  height: 100%;
  background: #000 url("/assets/background.jpg") no-repeat center center / cover;
  object-fit: cover;
}

.ion-page {
  height: 100%;
  background: #000 url("/assets/background.jpg") no-repeat center center / cover;
  object-fit: cover;
}

app-email-modal.ion-page {
  background: black !important;
}

app-slots-modal.ion-page {
  background: black !important;
}


.fill {
  height: 100%;
  width: 100%;
}

ion-content {
  --background: rgba(0, 0, 0, 0);
}

.place-self-center {
  place-self: center !important;
}

.bold-rounded {
  font-weight: bold;
}

.center {
  align-self: center;
  text-align: center;
  vertical-align: middle;
}

.end {
  text-align: end;
  horiz-align: right;
}

.ratio-wrapper {
  height: 0;
  overflow: hidden;
  position: relative;
}

.ratio-1-1 {
  padding-top: 100%;
}

.ratio-inside {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.popover-content {
  height: 100%
}

.popover-arrow {
  --background: black
}

.no-text-transform {
  text-transform: none !important;
}

.text-small {
  font-size: small;
}

.text-large {
  font-size: large;
}

.text-x-large {
  font-size: x-large;
}

.text-x-large {
  font-size: x-large;
}

.text-xm-large {
  font-size: 26px;
}

.text-xxl-large {
  font-size: 45px;
}

.text-xx-large {
  font-size: xx-large;
}

body {
  background: #000 url(/assets/background.jpg) no-repeat center center/cover;
  position: static;
  overflow: visible;
  object-fit: cover;
}

.center-vertical {
  display: flex;
  align-content: center;
  align-items: center;
}

.white {
  fill: white;
  color: white;
}

.black {
  fill: black;
  color: black;
}

.grey {
  fill: grey;
  color: grey;
}

.dark {
  fill: #333333;
  color: #333333;
}


.fuchsia-dark {
  fill: var(--ion-color-primary);
  color: var(--ion-color-primary);
}

.flex-direction-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.flex-direction-column > * {
  flex: 1;
  width: 100%
}

.header-md:after {
  display: none;
}

.fit-content {
  width: fit-content;
}

span {
  display: block;
}

.white-border-end {
  border-right: 1px solid white;
}

.invisible {
  opacity: 0;
}


.flex {
  display: flex;
}

.picker-button {
  color: white !important
}

.picker-wrapper {
  background: black !important;
  color: white !important;
}

.picker-opts {
  min-width: 35% !important;
}

.picker-opt-selected {
  color: white !important;
  border: 1px solid var(--ion-color-primary)
}

app-category app-toolbar-step ion-col {
  flex: 1 !important;
  width: 100% !important;
  max-width: 100% !important;

}

app-order-content {
  display: block;
  height: 100%;
}

// Footer button

.footer-button {
  background-color: var(--ion-color-primary);
  color: white;
  padding-left: 8px;
  padding-right: 8px;
  min-height: 60px;
  display: flex;
  cursor: pointer;
}

.footer-button > div {
  position: relative;
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
  @include mobile {
    padding-right: 0;
  }
}

.footer-button > ion-row > div {
  position: relative;
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
}

.footer-button > ion-row {
  height: 100%;
  display: flex;
  width: 100%;
  place-self: center;
}

.footer-button.valid {
  background-color: var(--ion-color-primary);
  cursor: pointer;
}

.footer-button-separator {
  height: 35px;
  place-self: center;
  background-color: white;
  width: 1px;
  margin-left: 16px;
  margin-right: 16px;
}

.footer-button > div > .text-small {
  margin-top: 3px;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
}

.footer-button-label {
  margin-right: auto;
}


// Calendar
ion-calendar {
  background-color: black !important;
}

button.days-btn[disabled] > p {
  color: grey !important;
}

button.days-btn > p {
  color: white !important;
}

button.days-btn.today > p {
  color: var(--ion-color-primary) !important;
}

input[type=checkbox] {
  height: 30px;
  width: 30px;
}

.modal-wrapper {
  --width: 400px !important;
  max-height: 90vh !important;
  --border-radius: 0;

  @include mobile {
    max-width: 100vw !important;
    max-height: 100vh !important;
  }
}

.modal-wrapper > .ion-page {
  height: 100%;
  overflow: hidden;
}

.modal-wrapper.auto-size {
  height: auto !important;
}

ion-modal.auto-size {
  &.bottom {
    align-items: flex-end;
  }

  --height: auto;

  .ion-page {
    position: relative;
    display: block;
    contain: content;

    .modal-content {
      max-height: 80vh;
      overflow: auto;
      padding: 10px;
    }
  }
}

@media only screen and (min-width: 992px) {
  .modal-wrapper {
    max-width: 75vw !important;
    max-height: 90vh !important;
  }
}

@media only screen and (min-width: 1200px) {
  .modal-wrapper {
    max-width: 50vw !important;
    max-height: 90vh !important;
  }
}

app-splash {
  justify-content: center !important;
  align-items: center;
}

.display-inline {
  display: inline !important;
}

.display-none {
  display: none !important;
}

.shrink {
  flex: 1;
}

.checkbox {
  height: 30px;
  width: 30px;
  background: url("/assets/checkbox.png") no-repeat center center / cover
}

.checkbox.checked {
  background: url("/assets/checkbox-checked.png") no-repeat center center / cover
}

.option-group-background {
  position: absolute;
  right: 0;
  width: 31%;
}

.gpay-button {
  background-origin: content-box;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(60, 64, 67, 0.3) 0 1px 1px 0, rgba(60, 64, 67, 0.15) 0 1px 3px 1px;
  cursor: pointer;
  height: 40px;
  padding: 9.3px;
  margin: 8px;
  background-image: url(https://www.gstatic.com/instantbuy/svg/light/de.svg);
  background-color: #fff;
  width: 100%;
}

.apay-button {
  height: 40px;
  margin: 8px;
  -webkit-appearance: -apple-pay-button;
  -apple-pay-button-type: check-out;
  -apple-pay-button-style: white;
}


.popover-content.sc-ion-popover-ios {
  border-radius: 0;
}

.month-packer-item > button {
  color: white
}

.cursor-pointer {
  cursor: pointer;
}

.article-details-modal > .modal-wrapper {
  @media (min-width: 768px) {
    height: 80vh !important;
    max-height: 80vh !important;
  }
}

app-order-list.full {
  .item {
    flex: 1 !important;
    width: 100% !important;
    max-width: 100% !important;
  }
}

.article-details-modal .modal-wrapper {
  width: 500px;
  @include mobile {
    width: 100%;
  }
}


app-payment-modal {
  overflow: auto !important;
}

.cc-animate.cc-revoke.cc-bottom {
  transform: translateY(5em) !important;
}

.cc-animate.cc-revoke.cc-active.cc-bottom {
  transform: translateY(0em) !important;
}

.wpwl-container.wpwl-container-card {
  background: #fff;
  border-radius: 5px;
  margin-top: 5px;
  padding: 10px;
}

.wpwl-form {
  margin: 0 auto !important;
}

.gpay-card-info-container {
  width: 100% !important;
}

.cc-revoke {
  display: none !important;
}

.menu-popover {
  top: 92px !important;
  left: 0 !important;

  .popover-content {
    height: 100% !important;
    max-height: 100vh !important;
    top: 0 !important;
    right: 0 !important;
    left: unset !important;
    width: 400px;
    overflow: visible;
    @include mobile {
      width: 240px;
    }
  }

  app-menu-popover {
    height: 100%;

    &:before {
      content: "";
      display: block;
      position: absolute;
      width: 0;
      height: 0;
      background: #111112;
      z-index: -1;
      border-top: calc(100vh - 92px) solid #000;
      border-right: 400px solid #111112;
      @include mobile {
        border-right: 240px solid #111112;
      }
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      top: -12px;
      width: 0;
      right: 22px;
      height: 0;
      border-style: solid;
      border-width: 0 9px 12px 9px;
      border-color: transparent transparent #000 transparent;
    }
  }
}

.password {
  width: 100%;
  position: relative;
}

.eye-icon {
  position: absolute;
  right: 15px;
  width: 21px;
  cursor: pointer;
  top: 50%;
  transform: translateY(-50%);
  z-index: 999;
}


// .cinema-picker::part(icon) {
//   position: relative;
//   opacity: 1;
//   &:before {
//     content: '';
//     position: absolute;
//     background: url("/assets/icon/picker.png");
//     width: 20px;
//     background-size: cover;
//     height: 20px;
//     z-index: 999;
//   }
// }

.reg-modal .modal-wrapper {
  height: 80vh !important;
  background: url("/assets/background.jpg");
}

.email-modal .modal-wrapper {
  height: 80vh !important;
  width: 700px !important;
  background: url("/assets/background.jpg");
  background-size: cover;
  @include mobile {
    height: -webkit-fill-available !important;
  }
}

.venue-modal .modal-wrapper {
  @include mobile {
    width: 90%;
  }
}


.checkbox_cookie_consent {
  display: none;
}


.cc_dialog.simple {
  width: 100% !important;
  max-width: 100% !important;
  left: 0;
  right: 0;
  padding: 0.5rem;

  p {
    font-size: 10px !important;
  }

  h1 {
    font-size: 16px;
  }
}

.cc_cp_f_powered_by {
  display: none !important;
  content: none !important;
}

.cc_cp_m_content_entry {
  height: auto !important;
  max-height: 500px !important;
  overflow: auto !important;
}

.dark.cc_dialog button.cc_b_ok {
  background-color: var(--ion-color-primary) !important;
  color: white !important;
}

.dark.cc_dialog button.cc_b_cp {
  background-color: dimgrey !important;
  color: white !important;
}

.close-row {
  padding: 16px;
  place-content: flex-end;

  ion-icon {
    height: 42px;
    width: 42px
  }
}

qr-code {
  padding: 16px;
  background: white;
}

.wpwl-group-button {
  display: flex;
  place-content: center;
  flex-direction: row;
}

.wpwl-wrapper {
  width: 100%
}

.wpwl-control {
  background: #FAFAFA;
  border: none;
  border-radius: 28px;
  padding: 4px 8px;
  color: #000;
}

.wpwl-label {
  width: 100%;
  font-size: 13px;
  padding: 4px 10px;
  color: #000;
}

.wpwl-wrapper-brand {
  width: 80%;
}

.wpwl-brand-card {
  width: 20%;
}

.wpwl-sup-wrapper-state {
  display: none;
}

.wpwl-sup-wrapper-street2 {
  display: none;
}

.wpwl-button-pay {
  width: 100%;
  border-radius: 3px;
  background-color: var(--ion-color-primary);
  border-color: var(--ion-color-primary);
}

app-tos, app-impressum, app-privacy {
  --ion-text-color: white !important
}

ion-row.center {
  justify-content: center;
  height: 100%;
  place-content: center;
}