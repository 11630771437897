@mixin mobile {
  @media (max-width: 991px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 991px) {
    @content;
  }
}

